import './App.css';
import React from 'react';
import PageHeader from './PageHeader';
import './video-icon.css';
import { WOW } from 'wowjs';
import { useEffect } from 'react';
//import OwlCarousel from 'react-owl-carousel';
//import './assets/css/owl.carousel.min.css';
import './temp-video-icon.scss';
import bgImage from './assets/img/hero/something3.png';


const OfferCard = ({ image, title, description }) => (
  <div className="col-lg-4 col-md-6 col-sm-4" >
    <div className="single-cat text-center mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
      <div className="cat-icon">
        <img src={image} alt="" width="362" height="300" />
      </div>
      <div className="cat-cap">
        <h5>
          <a href="./pricing">{title}</a>
        </h5>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

const OurOffers = ({ showHeader = true, showSpiderman = true, showRotateContent=true, showContent=true}) => {
  const offers = [
    {
      image: "https://i.postimg.cc/fWBy9sKX/rsz-livetv-downscale.jpg",
      title: "Live 4K TV",
      description: "You'll get access to over 22 000 live TV Channels across the World. From Europe, South Africa, Asia & America etc."
    },
    {
      image: "https://i.postimg.cc/bw2wWtjH/rsz-1money-edt.png",
      title: "Series",
      description: "You'll get access to over 80 000 Latest and Trending Series from Netflix, Amazon Prime, Apple TV & Disney etc."
    },
    {
      image: "https://i.postimg.cc/mrfgsgLx/rsz-movies-edt.jpg",
      title: "Movies",
      description: "You'll get access to over 98 000 Latest and Trending Movies from Netflix, Amazon Prime, Apple TV & Disney etc."
    }
  ];
  useEffect(() => {
    const wow = new WOW({ live: false });
    wow.init();
  }, []);


  const Content = () => (
    <section className="team-area fix">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="section-tittle text-center mb-55 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
            <h2>What We Offer</h2>
          </div>
        </div>
      </div>
      <div className="row">
        {offers.map((offer, index) => (
          <OfferCard key={index} {...offer} />
        ))}
      </div>
    </div>
  </section>
  );
  const Rotate_content = () => (
    <div className="carousel-container section-tittle text-center mb-55 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
    <h2 style={{ fontSize: "xxx-large" }}>Top Trends</h2>
    <div className="carousel-row row-right-to-left">
      <img src="https://i.postimg.cc/KcszHXYt/copaamerica.jpg" alt="" className="highlighted" />
      <img src="https://i.postimg.cc/KjKZ2zYB/epl.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/KcszHXYt/copaamerica.jpg" alt="" className="highlighted"/>
            <img src="https://i.postimg.cc/KjKZ2zYB/epl.jpg" alt="" class="highlighted"/>
            <img src="https://i.postimg.cc/bwfq7qfS/laliga.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/sxt3DrB8/legue11.jpg" alt="" class="highlighted"/>
            <img src="https://i.postimg.cc/pybtMJ4M/movie3.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/FHbhmwhW/messi1.jpg" alt="" class="highlighted"/>
            <img src="https://i.postimg.cc/DZ0hrZLH/movie1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/kgCBxQ4H/nba4.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/h4Jzg462/psl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/k4L5D8zf/raw1.jpg" alt="" class="highlighted"/>
            <img src="https://i.postimg.cc/Dwcm5S4w/seria-a1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/GtYmDt1r/series1-vikings.jpg" alt="" class="highlighted"/>
            <img src="https://i.postimg.cc/7Lsh8jVv/ufc1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/xCp0hH99/f1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/GtNm8DfX/bundesliga2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Y9Crd8KX/epl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/sxt3DrB8/legue11.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Gpcdpz2c/movie2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/FF2fK5QN/raw2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/mr2knT7N/seria-a2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/2616zTc1/vikings.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/CLqFrzXV/messi-cr7.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/xdYTznsV/bundesliga3.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/pdqx58hK/movie4.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Y9Crd8KX/epl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/h4Jzg462/psl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/GtYmDt1r/series1-vikings.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/kXK261n8/psl.png" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/pybtMJ4M/movie3.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/MGDZ0N4M/iamlast.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/KcszHXYt/copaamerica.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/KjKZ2zYB/epl.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/bwfq7qfS/laliga.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/sxt3DrB8/legue11.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/pybtMJ4M/movie3.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/FHbhmwhW/messi1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/DZ0hrZLH/movie1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Bb1GzVhC/movie5.jpg" alt="" className="highlighted" />
   
            <img src="https://i.postimg.cc/kgCBxQ4H/nba4.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/h4Jzg462/psl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/k4L5D8zf/raw1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Dwcm5S4w/seria-a1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/GtYmDt1r/series1-vikings.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/7Lsh8jVv/ufc1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/xCp0hH99/f1.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/GtNm8DfX/bundesliga2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Y9Crd8KX/epl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/sxt3DrB8/legue11.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Gpcdpz2c/movie2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/FF2fK5QN/raw2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/MGDZ0N4M/iamlast.jpg" alt="" className="highlighted" />

            <img src="https://i.postimg.cc/mr2knT7N/seria-a2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/2616zTc1/vikings.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/pybtMJ4M/movie3.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/xdYTznsV/bundesliga3.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/pdqx58hK/movie4.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/Y9Crd8KX/epl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/h4Jzg462/psl2.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/GtYmDt1r/series1-vikings.jpg" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/kXK261n8/psl.png" alt="" className="highlighted" />
            <img src="https://i.postimg.cc/MGDZ0N4M/iamlast.jpg" alt="" className="highlighted" />
    </div>
  </div>
  );

const Spiderman = () => (
  <div 
  className="video-area section-bg2 d-flex align-items-center" 
  style={{ backgroundImage: `url("https://i.postimg.cc/vBG842x3/services-bg.png")` }}
>
  <div className="container">
    <div className="video-wrap position-relative">
      <div className=" video-icon">
        <a 
        
          href="whatsapp://send?phone=+27671473686&text=Hello, Hello, Stream Line TV I would like to watch Spiderman for free."
        >
          <i className="fas fa-play"></i>
        </a>
      </div>
    </div>
  </div>
</div>

/*<div 
className="video-area section-bg2 d-flex align-items-center"
style={{ backgroundImage: 'url(https://i.postimg.cc/vBG842x3/services-bg.png)' }} // Using inline style for background image
>
<div className="container">
  <div className="video-wrap position-relative">
    <div className="video-icon">
      <a 
        className="btn-icon" 
        href="whatsapp://send?phone=+27671473686&text=Hello, Hello, Stream Line TV I would like to watch spiderman for free.."
      >
        <i className="fas fa-play"></i>
      </a>
    </div>
  </div>
</div>
</div>*/
);
  return (
    <>

      {showHeader && <PageHeader title="OUR OFFERS"  backgroundImage={bgImage}/>}
      {showContent && <Content/>}{showRotateContent && <Rotate_content/>} {showSpiderman && <Spiderman />}
     

    </>
  );
};


export default  OurOffers;